<template>
	<div class="contanier">
		<el-tabs type="border-card" v-model="tableTabs" @tab-click="handleClick">
			<el-tab-pane label="选择商品规格" name="first">
				<el-table ref="tab" height="350" :data="spec.list" border row-key="skuId" @selection-change="handleTabChange">
					<el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
					<el-table-column prop="specValues" label="规格名称"></el-table-column>
					<el-table-column prop="salePrice" label="销售价格"></el-table-column>
					<el-table-column prop="stock" label="现有库存"></el-table-column>
					<el-table-column label="状态" width="120">
						<template slot-scope="scope">
							<div v-if="!scope.row.stock" class="table-button" style="color: #f00;">库存不足</div>
						</template>
					</el-table-column>
				</el-table>
				<div style="display: flex; align-items: center; margin-top: 20px">
					<el-checkbox style="flex: 1;" @change="tabCheckedChange('tab')" v-model="spec.checked">全选</el-checkbox>
					<div>
						<el-button @click="cancelEdit">返回商品选择</el-button>
						<el-button v-if="!spec.selection.length" type="info">确认选择</el-button>
						<el-button v-else type="primary" @click="nextStep()">确认选择</el-button>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="设置价格和库存" name="second">
				<el-table ref="tabSet" height="350" :data="check.list" border row-key="skuId" @selection-change="handleCheckChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="specValues" label="规格名称"></el-table-column>
					<el-table-column label="换购价">
						<template slot-scope="scope">
							<div>
								<el-input @keyup.native="swapPriceChange(scope)"
										  v-model="scope.row.swapPrice" size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
								<div style="margin-top: 10px;margin-left: 10px;">价格(元)：{{scope.row.salePrice}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="换购库存">
						<template slot-scope="scope">
							<div>
								<el-input @keyup.native="swapStockChange(scope)" v-model="scope.row.swapStock"
										  size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
								<div style="margin-top: 10px;margin-left: 10px;">现有库存：{{scope.row.stock}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="限购量">
						<template slot-scope="scope">
							<div>
								<el-input @keyup.native="swapRateChange(scope)" v-model="scope.row.swapRate"
										  size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
								<div style="margin-top: 10px;margin-left: 10px;color: #fff;">.</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<el-button class="table-button" type="danger" size="mini" @click="removeCheckSku(scope)">取消参加</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex; align-items:center; margin-left: 13px;">
					<el-checkbox @change="tabCheckedChange('tabSet')" v-model="check.checked">全选</el-checkbox>
					<el-button style="margin-left: 20px;" type="text" size="mini" @click="removeCheckSkuAll()">批量删除</el-button>
				</div>
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div style="display: flex; align-items: center;">
						<div style="margin-left: 10px;">批量设置</div>
						<el-button style="margin-left: 20px;" type="text" @click="setPrice">换购价</el-button>
						<el-button style="margin-left: 20px;" type="text" @click="setStock">换购库存</el-button>
						<el-button style="margin-left: 20px;" type="text" @click="setPoint">限购量</el-button>
					</div>
					<div>
						<el-button @click="prevStep">返回规格选择</el-button>
						<el-button v-if="!check.list.length" type="info">确认提交</el-button>
						<el-button v-else type="primary" @click="commitEdit">确认提交</el-button>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>

		<el-dialog :title="batchTitle" :visible.sync="batchEditDialog" width="450px" class="dialog" append-to-body>
			<div>
<!--				<el-input @input="inputChange" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"-->
<!--						  v-model="batchKillPrice" class="input-with-select" style="width: 400px;margin: 0 5px;" placeholder="请输入数值"></el-input>-->

				<el-input @keyup.native="inputChange()" v-model="batchValue" placeholder="请输入数值" class="input-with-select"></el-input>

				<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
					<el-button style="margin-left: 20px;" type="warning" @click="batchEditDialog=false">取消</el-button>
					<el-button style="margin-left: 20px;" type="primary" @click="batchEditCommit()">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {getGoodsSampleDetail,} from "@/api/goods";

	export default {
	props: {
		formData: {
			type: Object,
			default: null,
		},
		swapGoodsList: {
			type: Array,
			default: null,
		},
	},
	data () {
		return {
			spec: {
				checked: false,
				list: [],
				selection: [],
			},
			check: {
				checked: false,
				list: [],
				selection: [],
			},

			batchEditDialog: false,
			batchEditType: 1, //1 批量设置价格 2批量设置库存,3批量设置金豆
			batchKillPrice: '',
			batchTitle: '批量设置',
			batchMax: 0, //限制最大值
			batchValue: 0, //输入值
			baseData: [],

			tableTabs: 'first',
		}
	},
	mounted () {
		this.getSpecList();
	},
	methods: {
		selectable(row){
			if(!row.stock){
				return false;
			} else {
				return true;
			}
		},
		//换购价
		swapPriceChange({row}) {
			console.log(row.swapPrice);
			// 先把非数字的都替换掉，除了数字和.
			row.swapPrice = row.swapPrice.replace(/[^\d.]/g, '');
			// 必须保证第一个为数字而不是.
			row.swapPrice = row.swapPrice.replace(/^\./g, '');
			// 保证只有出现一个.而没有多个.
			row.swapPrice = row.swapPrice.replace(/\.{2,}/g, '');
			// 保证.只出现一次，而不能出现两次以上
			row.swapPrice = row.swapPrice.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
			let index = -1;
			for (let i in row.swapPrice) {
				if (row.swapPrice[i] === '.') {
					index = i
				}
				if (index !== -1) {
					if (i - index > 2) {
						row.swapPrice = row.swapPrice.substring(0, row.swapPrice.length - 1)
					}
				}
			}
			if(row.swapPrice > row.salePrice) {
				row.swapPrice = row.salePrice;
			}
		},
		//换购库存
		swapStockChange({row}) {
			// 先把非数字的都替换掉，除了数字和.
			row.swapStock = row.swapStock.replace(/[^\d.]/g, '');
			// 必须保证第一个为数字而不是.
			row.swapStock = row.swapStock.replace(/^\./g, '');
			// 保证没有.
			row.swapStock = row.swapStock.replace(/\.{1,}/g, '');
			if(row.swapStock > row.stock) {
				row.swapStock = row.stock;
			}
		},
		//限购量
		swapRateChange({row}) {
			// 先把非数字的都替换掉，除了数字和.
			row.swapRate = row.swapRate.replace(/[^\d.]/g, '');
			// 必须保证第一个为数字而不是.
			row.swapRate = row.swapRate.replace(/^\./g, '');
			// 保证没有.
			row.swapRate = row.swapRate.replace(/\.{1,}/g, '');
			if(row.swapRate > row.stock) {
				row.swapRate = row.stock;
			}
		},
		async getSpecList() {
			let { success, data, alertMsg} = await getGoodsSampleDetail({
				id: this.formData.id,
			});
			if (success) {
				this.spec.list = data.skuSampleVOList;
			} else {
				this.$message({ showClose: true, type: 'error', message: alertMsg || '未知错误' });
			}
		},
		tabCheckedChange(name) {
			this.$refs[name].toggleAllSelection();
		},
		handleTabChange(arr) {
			this.spec.selection = arr;
			this.spec.checked = (arr.length === this.spec.list.length);
		},
		nextStep() {
			this.check.list = this.spec.selection.filter(item => {
				return item;
			});
			this.tableTabs = 'second';
		},
		handleCheckChange(arr) {
			this.check.selection = arr;
			this.check.checked = (arr.length === this.check.list.length);
		},
		prevStep() {
			this.check.list = [];
			this.tableTabs = 'first';
		},
		//删除选中商品
		removeCheckSku({row, $index}) {
			this.check.list.splice($index, 1);
		},
		//批量删除选中商品
		removeCheckSkuAll() {
			if(!this.check.selection.length) {
				return;
			}
			let deleteArr = [];
			this.check.selection.map(item => {
				deleteArr.push(item.skuId);
			});
			let rest = [];
			this.check.list.map(item => {
				if(deleteArr.indexOf(item.skuId) <= -1) {
					rest.push(item);
				}
			});
			this.check.list = rest;
			this.check.checked = false;
		},
		//批量输入值
		setPrice() {
			this.batchTitle = "批量设置换购价";
			this.batchKillPrice = '';
			this.batchEditType = 1;
			this.batchValue = '';
			this.batchMax = this.getMaxValue('salePrice');
			this.batchEditDialog = true;
		},
		setStock() {
			this.batchTitle = "批量设置换购库存";
			this.batchKillPrice = '';
			this.batchEditType = 2;
			this.batchValue = '';
			this.batchMax = this.getMaxValue('stock');
			this.batchEditDialog = true;
		},
		setPoint() {
			this.batchTitle = "批量设置限购量";
			this.batchKillPrice = '';
			this.batchEditType = 3;
			this.batchValue = '';
			this.batchMax = this.getMaxValue('stock');
			this.batchEditDialog = true;
		},
		//获取最大值
		getMaxValue(name) {
			let max = 100000;
			this.check.list.filter(item => {
				if(item[name] < max) {
					max = item[name];
				}
			});
			return max;
		},
		inputChange(e) {
			// 先把非数字的都替换掉，除了数字和.
			this.batchValue = this.batchValue.replace(/[^\d.]/g, '');
			// 必须保证第一个为数字而不是.
			this.batchValue = this.batchValue.replace(/^\./g, '');
			// 保证没有.
			this.batchValue = this.batchValue.replace(/\.{1,}/g, '');

			if(this.batchValue < 0) {
				this.batchValue = 0;
			}
			if(this.batchValue > this.batchMax) {
				this.batchValue = this.batchMax;
			}

			if (this.batchEditType === 2 || this.batchEditType === 3) {
				this.batchKillPrice = parseInt(this.batchValue);
			} else if (this.batchEditType === 1) {
				this.batchKillPrice = (Math.abs(this.batchValue)).toFixed(2);
			}
			// this.$forceUpdate()
		},
		//确定批量设置
		batchEditCommit() {
			let str = '';
			if(!this.batchKillPrice) {
				switch (this.batchEditType) {
					case 1:
						str = '请填写换购价';
						break;
					case 2:
						str = '请填写换购库存';
						break;
					case 3:
						str = '请填写库存量';
						break;
				}
			}
			if (!str && isNaN(this.batchKillPrice)) {
				str = '请填写正确的数值';
			}
			if (str) {
				this.$message({showClose: true, type: 'warning', message: str});
				return;
			}
			let tempNum = this.batchKillPrice;
			let temp = JSON.parse(JSON.stringify(this.check.list));
			if (this.batchEditType === 1) {
				let money = parseFloat(tempNum);
				let arr = tempNum.split('.');
				let num = '';
				let price = '';
				if(arr.length > 1){
					if(arr[1].length > 2){
						num = arr[1].substr(0,2);
					} else{
						num = arr[1];
					}
					price = arr[0]+'.'+num;
				} else {
					price = money;
				}
				temp.map(item => {
					item.swapPrice = price + '';
					return item;
				})
			} else if(this.batchEditType === 2) {
				let isOverStock = false;
				temp.map(item => {
					if (item.stock < tempNum) {
						isOverStock = true;
					}
					return item;
				});
				if (isOverStock) {
					this.$message({showClose: true, type: 'warning', message: '有规格库存不足，请重新设置'});
				} else {
					temp.map(item => {
						item.swapStock = tempNum + '';
						return item;
					})
				}
			} else {
				let isOverStock = false;
				temp.map(item => {
					if (item.stock < tempNum) {
						isOverStock = true;
					}
					return item;
				});
				if (isOverStock) {
					this.$message({showClose: true, type: 'warning', message: '有规格库存不足，请重新设置'});
				} else {
					temp.map(item => {
						item.swapRate = tempNum + '';
						return item;
					})
				}
			}
			this.check.list = temp;
			this.batchEditDialog = false;
			this.$forceUpdate();
		},
		//回显选中的规格
		handleClick(tab) {
			console.log(tab);
		},
		//取消
		cancelEdit(){
			this.$emit('closeSpec');
		},
		//确认提交
		commitEdit() {
			let error = '';
			this.check.list.filter(item => {
				if (!item.swapPrice) {
					error = '请填写换购价';
					return
				}
				if (!item.swapStock) {
					error = '请填写换购库存';
					return
				}
				if (!item.swapRate) {
					error = '请填写限购量';
					return
				}
				if (item.stock < item.swapStock) {
					error = '有规格库存不足，请重新设置';
					return
				}
			});
			if(error) {
				this.$message({showClose: true, type: 'warning', message: error});
				return;
			}
			let selectArray = [];
			this.check.list.filter((item, index) => {
				selectArray.push({
					swapPrice: (+item.swapPrice).toFixed(2),
					swapRate: (+item.swapRate),
					swapSkuId: (+item.skuId),
					swapStock: (+item.swapStock),
					orderWeight: this.swapGoodsList.length + index,
					thumbImgUrl: this.formData.thumbImgUrl,
					goodsName: this.formData.goodsName,
					specValues: item.specValues[0],
					salePrice: item.salePrice,
					growRate: item.growRate,
				});
			});
			this.$emit('getSelectList', selectArray);
		},
	}
}
</script>

<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}
	.noImgIcon {
		width: 60px;
		height: 60px;
	}
</style>