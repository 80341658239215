<template>
	<div class="contanier">
		<div v-if="!showSpec">
			<el-form inline size="small" label-width="100px">
				<el-form-item label="商品名称:">
					<el-input class="input-width" v-model="filter.goodsName" placeholder="请输入商品名称搜索"></el-input>
				</el-form-item>
				<el-form-item label="商品分组：">
					<el-select v-model="filter.categoryId" style="width: 200px;" placeholder="请选择">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="(item,index) in groupList" :key="index" :label="item.groupName" :value="item.groupId"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
				</el-form-item>
			</el-form>

			<el-table height="450" :data="filter.list" v-loading="loading" border size="small">
				<el-table-column prop="goodsName" label="商品" width="600">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img style="width: 100px" v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl" alt=""/>
							<div class="right">
								<div class="name" style="color: #007AFF;">{{scope.row.goodsName}}</div>
								<div style="margin-top: 10px;color: #f00;">￥{{scope.row.salePrice}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="salePrice" label="销售价格"></el-table-column>
				<el-table-column prop="totalStock" label="商品库存"></el-table-column>
				<el-table-column prop="noChoiceReason" label="商品状态" width="200px">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.shelfStatus" size="small" type="success" effect="dark">上架中</el-tag>
						<el-tag v-else size="small" type="danger" effect="dark">已下架</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="160">
					<template slot-scope="scope">
						<el-button v-if="scope.row.totalStock && scope.row.shelfStatus" size="mini" type="primary" @click="selectGoods(scope)">选择商品</el-button>
						<el-button v-else size="mini" type="info">不可选</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
						   :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
						   @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
		</div>

		<select-spec v-else-if="showSpecData" :formData="showSpecData"
					 @getSelectList="saveSelectProList"
					 :swapGoodsList="swapGoodsList"
					 @closeSpec="showSpec=false"></select-spec>
	</div>
</template>
<script>
	import Other from "@/api/other";
	import config from '@/config/index'
	import SelectSpec from './selectSpec.vue';

	export default {
	components: {
		SelectSpec,
	},
	props: {
		formData: {
			type: Object,
			default: null,
		},
		groupList: {
			type: Array,
			default: null,
		},
		swapGoodsList: {
			type: Array,
			default: null,
		},
	},
	data () {
		return {
			imgUrl: config.IMG_BASE,
			loading: false,
			filter: {
				goodsName: '',
				categoryId: '',
				goodsTags: '',
				pageNo: 1,
				pageSize: 10,
				total: 0,
				list: [],
			},
			showSpec: false,
			showSpecData: null,
		}
	},
	mounted () {
		this.search();
	},
	methods: {
		search() {
			this.filter.pageNo = 1;
			this.getSingleList();
		},
		// 翻页
		currentChange(val) {
			this.filter.pageNo = val;
			this.getSingleList();
		},
		// 切换显示条数
		pageSizeChange(val) {
			this.filter.pageSize = val;
			this.getSingleList();
		},
		async getSingleList() {
			this.loading = true;
			let params = {
				categoryId:this.filter.categoryId,
				goodsName:this.filter.goodsName,
				goodsTags:this.filter.goodsTags,
				pageNo: this.filter.pageNo, // 略过数据
				pageSize: this.filter.pageSize,  // 取的数据
			};
			let { success, data } = await Other.activitycouponChoseproductlist(params);
			if(success) {
				data.records.map(items=>{
					items.hasSecKill = false;
					items.quotaNum = '0';
					items.groupWard='';
					return items;
				});
				this.filter.total = data.total;
				this.filter.list = data.records || [];
			}
			setTimeout(() => {
				this.loading = false;
			}, 300)
		},
		selectGoods({row, $index}) {
			if(this.formData.type === 'goods') {
				this.saveSelectProList(row);
			}
			if(this.formData.type === 'spec') {
				this.showSpec = true;
				this.showSpecData = {...row};
			}
		},
		//外部全选当前页
		saveSelectProList(data) {
			this.$emit('getSelectList', data);
		},
		clearDataFunc() {
			this.showSpecData = null;
		},
	}
}
</script>

<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}
	.noImgIcon {
		width: 60px;
		height: 60px;
	}
</style>