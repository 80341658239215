<template>
	<div>
		<el-table height="450" :data="filter.list" v-loading="tableLoading" border size="small">
			<el-table-column prop="goodsName" label="商品" width="600">
				<template slot-scope="scope">
					<div class="dialog-name-content">
						<img style="width: 100px" v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl" alt=""/>
						<div class="right">
							<div class="name" style="color: #007AFF;">{{scope.row.goodsName}}</div>
							<div style="margin-top: 10px;color: #f00;">￥{{scope.row.salePrice}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="salePrice" label="销售价格"></el-table-column>
			<el-table-column prop="stock" label="商品库存"></el-table-column>
			<el-table-column label="操作" width="260">
				<template slot-scope="scope">
					<el-button v-if="!scope.row.usageState" size="mini" type="success" @click="changeStatus(scope)">继续换购</el-button>
					<el-button v-else size="mini" type="warning" @click="changeStatus(scope)">结束换购</el-button>
					<el-button v-if="!scope.row.usageState" size="mini" type="danger" @click="deleteItem(scope)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
					   :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
					   @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
	</div>
</template>
<script>
    import {getActivitySwapGoodsPage, updateActivitySwapGoodsState} from "@/api/goods";

    export default {
	props: {
		formData: {
			type: Object,
			default: null,
		},
	},
	data () {
		return {
			tableLoading: false,
			filter: {
				pageNo: 1,
				pageSize: 10,
				total: 0,
				list: [],
			},
		}
	},
	mounted () {
		this.search();
	},
	methods: {
		search() {
			this.filter.pageNo = 1;
			this.getList();
		},
		// 翻页
		currentChange(val) {
			this.filter.pageNo = val;
			this.getList();
		},
		// 切换显示条数
		pageSizeChange(val) {
			this.filter.pageSize = val;
			this.getList();
		},
		async getList() {
			this.loading = true;
			let params = {
				actSwapId:this.formData.id,
				pageNo: this.filter.pageNo, // 略过数据
				pageSize: this.filter.pageSize,  // 取的数据
			};
			let { success, data } = await getActivitySwapGoodsPage(params);
			if(success) {
				this.filter.total = data.total;
				this.filter.list = data.records || [];
			}
			setTimeout(() => {
				this.loading = false;
			}, 300)
		},
		//上下架
		changeStatus({row, $index}) {
			let item = {...row};
			let text = item.usageState ? '确认是结束换购' : '确认继续换购';
			this.$confirm(text, '温馨提醒', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let { success, alertMsg } = await updateActivitySwapGoodsState({
					id: item.id,
					usageState: item.usageState ? 0 : 1,
				});
				if (success) {
					item.usageState = item.usageState ? 0 : 1;
					this.filter.list.splice($index, 1, item);
					this.$message({type: 'success', message: '操作成功！'});
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误'});
				}
			}).catch(err => {
				console.error(err)
			})
		},
		//删除
		deleteItem({row, $index}) {
			this.$confirm('确认是否删除?', '温馨提醒', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let { success, alertMsg } = await updateActivitySwapGoodsState({ id: row.id, deletedFlag: 1});
				if (success) {
					this.filter.list.splice($index, 1);
					this.$message({type: 'success', message: '删除成功！'});
				} else {
					this.$message({type: 'error', message: alertMsg || '未知错误'});
				}
			}).catch(err => {
				console.error(err)
			})
		},
	}
}
</script>

<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}
	.noImgIcon {
		width: 60px;
		height: 60px;
	}
</style>