<template>
    <div class="main-content">
        <el-form inline size="small" label-width="100px">
            <el-form-item>
                <el-button type="primary" @click="addItem">添加换购</el-button>
            </el-form-item>
            <el-form-item label="商品ID：">
                <el-input class="input-width" v-model="filter.goodsId" placeholder="请输入商品ID搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品名称：">
                <el-input class="input-width" v-model="filter.goodsName" placeholder="请输入商品名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="上/下架状态：">
                <el-select class="input-width" v-model="filter.usageState" placeholder="请选择状态">
                    <el-option :value="''" label="全部"></el-option>
                    <el-option :value="0" label="已下架"></el-option>
                    <el-option :value="1" label="已上架"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker class="input-width" v-model="searchTimes" type="daterange" :picker-options="pickerOptions"
                                range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="filter.list" v-loading="tableLoading" border>
            <el-table-column prop="goodsId" label="商品ID" width="160"></el-table-column>
            <el-table-column label="商品主图" align="center" width="200">
                <template slot-scope="scope">
                    <div class="iv-heaer">
                        <img v-if="scope.row.mainImgUrl" :src="scope.row.mainImgUrl" alt="" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="goodsName" label="商品名称" min-width="240"></el-table-column>
            <el-table-column prop="relationGoodsCount" label="关联换购商品数" width="160"></el-table-column>
            <el-table-column label="上下架状态" width="160">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.usageState" active-color="#13ce66" inactive-color="#ff4949"
                               :active-value="1" :inactive-value="0"
                               @change="changeStatus(scope)"></el-switch>
<!--                    <el-tag v-if="scope.row.usageState" style="cursor: pointer" type="success" effect="dark" size="mini" @click="changeStatus(scope)">已上架</el-tag>-->
<!--                    <el-tag v-else type="danger" style="cursor: pointer" effect="dark" size="mini" @click="changeStatus(scope)">已下架</el-tag>-->
                </template>
            </el-table-column>
            <el-table-column prop="createDate" label="创建时间" width="180"></el-table-column>
            <el-table-column label="操作" width="240">
                <template slot-scope="scope">
                    <el-button size="mini" type="success" @click="editItem(scope)">编辑</el-button>
                    <el-button size="mini" type="primary" @click="selectItem(scope)">换购商品</el-button>
                    <el-button size="mini" type="danger" @click="deleteItem(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                       :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
                       @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>

        <el-dialog :title="addEditTitle" :visible.sync="addEditFlag" width="70%" @closed="clearDataFunc">
            <Edit v-if="addEditData" :formData="addEditData" @closeLayer="closeLayer"></Edit>
        </el-dialog>

        <el-dialog title="换购商品" :visible.sync="changeBuyGoodFlag" width="70%" @closed="clearDataFunc">
            <ChangeBuyGoods v-if="changeBuyGoodData" :formData="changeBuyGoodData"></ChangeBuyGoods>
        </el-dialog>
    </div>
</template>

<script>
    import {getActivitySwapPage, updateActivitySwapState} from '@/api/goods';
    import Edit from './edit';
    import ChangeBuyGoods from './changeBuyGoods';

    export default {
    components: {
        Edit,
        ChangeBuyGoods,
    },
    data() {
        return {
            tableLoading: false,
            filter: {
                goodsId: '',
                goodsName: '',
                usageState: '',
                pageNo: 1,
                pageSize: 10,
                total: 0,
                list: [],
            },
            searchTimes: null,
            pickerOptions: {
                shortcuts:  [{
                    text: '本月',
                    onClick(picker) {
                        const start = new Date();
                        start.setDate(1);
                        const end = new Date();
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上月',
                    onClick(picker) {
                        const start = new Date();
                        start.setMonth(start.getMonth() - 1);
                        start.setDate(1);

                        const end = new Date();
                        end.setDate(0);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上上月',
                    onClick(picker) {
                        const start = new Date();
                        start.setMonth(start.getMonth() - 2);
                        start.setDate(1);

                        const end = new Date();
                        end.setMonth(end.getMonth() - 1);
                        end.setDate(0);

                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().getFullYear(), 0);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }, //日期快捷选项

            addEditTitle: '添加换购商品',
            addEditFlag: false,
            addEditData: null,

            changeBuyGoodFlag: false,
            changeBuyGoodData: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        search() {
            this.filter.pageNo = 1;
            this.getList();
        },
        // 翻页
        currentChange(val) {
            this.filter.pageNo = val;
            this.getList();
        },
        // 切换显示条数
        pageSizeChange(val) {
            this.filter.pageSize = val;
            this.getList();
        },
        // 获取列表
        async getList() {
            this.tableLoading = true;
            let params = {
                goodsId: this.filter.goodsId,
                goodsName: this.filter.goodsName,
                usageState: this.filter.usageState,
                pageNo: this.filter.pageNo,
                pageSize: this.filter.pageSize,
            };
            if (this.searchTimes) {
                params.createDateFrom = this.searchTimes[0];
                params.createDateTo = this.searchTimes[1];
            }
            let { success, data } = await getActivitySwapPage(params);
            if (success) {
                this.filter.list = data.records || [];
                this.filter.total = data.total;
            }
            setTimeout(() => {
                this.tableLoading = false;
            }, 300);
        },
        closeLayer(flag) {
            this.addEditFlag = false;
            this.changeBuyGoodFlag = false;
            if(flag) {
                this.getList();
            }
        },
        //清楚选中内容
        clearDataFunc() {
            this.addEditData = null;
            this.changeBuyGoodData = null;
        },
        // 编辑/新增
        addItem() {
            this.addEditTitle = '添加换购商品';
            this.addEditData = {
                id: '',
            };
            this.addEditFlag = true;
        },
        editItem({row}) {
            this.addEditTitle = '编辑换购商品';
            this.addEditFlag = true;
            this.addEditData = {
                id: row.id,
            };
        },
        //换购商品管理
        selectItem({ row }) {
            this.changeBuyGoodData = { ...row };
            this.changeBuyGoodFlag = true;
        },
        //上下架
        async changeStatus({row, $index}) {
            let item = {...row};
            item.usageState = row.usageState ? 0 : 1;
            let text = item.usageState ? '确认是否下架' : '确认是否上架';
            this.$confirm(text, '温馨提醒', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let { success, alertMsg } = await updateActivitySwapState({
                    id: item.id,
                    usageState: item.usageState ? 0 : 1,
                });
                if (success) {
                    item.usageState = item.usageState ? 0 : 1;
                    this.filter.list.splice($index, 1, item);
                    this.$message({type: 'success', message: '操作成功！'});
                } else {
                    this.$message({type: 'error', message: alertMsg || '未知错误'});
                }
            }).catch(err => {
                this.filter.list.splice($index, 1, item);
            })
        },
        //删除
        deleteItem({row, $index}) {
            this.$confirm('确认是否删除?', '温馨提醒', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let { success, alertMsg } = await updateActivitySwapState({ id: row.id, deletedFlag: 1});
                if (success) {
                    this.filter.list.splice($index, 1);
                    this.$message({type: 'success', message: '删除成功！'});
                } else {
                    this.$message({type: 'error', message: alertMsg || '未知错误'});
                }
            }).catch(err => {
                console.error(err)
            })
        },
    }
}
</script>
<style scoped lang="less">
    .iv-heaer>img {
        width: 100px;
        height: 100px;
    }
    .input-width {
        width: 240px;
    }
</style>