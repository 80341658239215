<template>
    <div>
        <el-form v-loading="tableLoading" :model="activitySwap" :rules="rules" ref="goodsCreateFrom" label-width='160px' size="mini">
            <el-form-item label="主商品选择：">
                <el-button v-if="!goodsList.length" style="margin-bottom: 10px" size="mini" type="primary" @click="selectGoods('goods')">添加主商品</el-button>
                <el-table :data="goodsList" border size="mini">
                    <el-table-column prop="id" label="商品ID" width="100"></el-table-column>
                    <el-table-column label="商品主图" align="center" width="160">
                        <template slot-scope="scope">
                            <div class="iv-heaer" style="width: 80px; height: auto; margin: auto;">
                                <img style="display: block; width: 100%; height: auto;" v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl" alt="" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsName" label="商品名称" min-width="240"></el-table-column>
                    <el-table-column prop="salePrice" label="销售价格" width="100"></el-table-column>
                    <el-table-column prop="growRate" label="活跃值" width="100"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button v-if="!formData.id" size="mini" type="danger" @click="deleteGoods(scope)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item label="换购商品选择：" prop="specList">
                <div class="flex">
                    <el-button style="margin-bottom: 10px" size="mini" type="primary" @click="selectGoods('spec')">添加换购商品</el-button>
                </div>
                <el-table class="table-back-write" :data="activitySwap.swapGoodsList" border size="mini">
                    <el-table-column prop="swapSkuId" label="商品规格ID" width="100"></el-table-column>
                    <el-table-column label="商品主图" align="center" width="160">
                        <template slot-scope="scope">
                            <div class="iv-heaer" style="width: 80px; height: auto; margin: auto;">
                                <img style="display: block; width: 100%; height: auto;" v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl" alt="" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsName" label="商品名称" min-width="240"></el-table-column>
                    <el-table-column prop="specValues" label="商品规格" width="120"></el-table-column>
                    <el-table-column prop="salePrice" label="商品销售价" width="100"></el-table-column>
                    <el-table-column prop="swapPrice" label="换购价（销售价）" width="120"></el-table-column>
                    <el-table-column prop="growRate" label="活跃值" width="100"></el-table-column>
                    <el-table-column prop="swapStock" label="库存量" width="100"></el-table-column>
                    <el-table-column prop="swapRate" label="限购数量" width="120">
                        <template slot-scope="scope">
                            <span style="margin-right: 10px">{{ scope.row.swapRate }}</span>
                            <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="editSpecNum(scope, 'swapRate')"></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderWeight" label="排序字段" width="120">
                        <template slot-scope="scope">
                            <span style="margin-right: 10px">{{ scope.row.orderWeight }}</span>
                            <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="editSpecNum(scope, 'orderWeight')"></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="deleteSpec(scope)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item label="换购商品是否多选：" prop="multipleChoiceFlag">
                <el-switch v-model="activitySwap.multipleChoiceFlag"></el-switch>
            </el-form-item>
            <el-form-item label="是否立即起效：" prop="usageState">
                <el-switch v-model="activitySwap.usageState"></el-switch>
            </el-form-item>
            <el-form-item label="换购活动时间：" prop="isShowTime">
                <el-switch v-model="isShowTime" style="margin-right:30px;"></el-switch>
                <el-date-picker
                    v-if="isShowTime"
                    v-model="showTime"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <div class="dialog-footer">
                    <el-button @click="cancelFunc()">取 消</el-button>
                    <el-button type="primary" @click="submitData()">确 定</el-button>
                </div>
            </el-form-item>
        </el-form>

        <el-dialog :title="selectGoodsTitle" :visible.sync="selectGoodsFlag" width="70%" append-to-body @closed="clearDataFunc">
            <selectGoods v-if="selectGoodsData" :formData="selectGoodsData" :swapGoodsList="activitySwap.swapGoodsList" :groupList="groupList" @getSelectList="getSelectList"></selectGoods>
        </el-dialog>


        <el-dialog :title="showChangeTitle" :visible.sync="showChangeNumFlag" width="520px" append-to-body>
            <el-form label-width="100px">
                <el-form-item label="输入值：" prop="userPhone">
                    <el-input-number v-model="showChangeData.value" placeholder="请输入修改值" :max="showChangeData.max"></el-input-number>
                </el-form-item>
                <el-form-item>
                    <div class="dialog-footer">
                        <el-button @click="showChangeNumFlag=false">取 消</el-button>
                        <el-button type="primary" @click="checkChangeNum()">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {editActivitySwap, getActivitySwap, productGroupListData} from '@/api/goods';
    import selectGoods from './selectGoods.vue';

    export default {
        components: {
            selectGoods,
        },
        props: {
            formData: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                tableLoading: false,
                activitySwap: {
                    keyId: '',
                    swapGoodsList: [],
                    multipleChoiceFlag: false,
                    usageState: false,
                    operateType: 1,
                    swapBeginTime:'',   //换购开始时间
                    swapEndTime:'',     //换购结束时间
                },
                rules: {
                    keyId: [
                        {required: true, message: '请添加商品规格', trigger: 'blur'},
                    ],
                    swapGoodsList: [
                        {required: true, message: '请添加商品规格', trigger: 'blur'},
                    ],
                },
                goodsList: [],

                selectGoodsFlag: false,
                selectGoodsData: null,
                selectGoodsTitle: '选择主商品',
                groupList: [],

                showChangeNumFlag: false,
                showChangeData: {
                    type: 'swapRate',
                    value: 0,
                    max: 0
                },
                showChangeTitle: '修改数值',
                isShowTime:false,    //时间展示开关
                showTime:[],    //展示时间
            }
        },
        mounted() {
            this.getGroupList();
            if(this.formData.id) {
                this.getActivityDetail();
            }
        },
        methods: {
            // 获取分组列表数据
            async getGroupList () {
                let { data } = await productGroupListData({
                    pageNo: 0, // 略过数据
                    pageSize: 10000, // 取的数据
                });
                if (data) {
                    this.groupList = data.records || [];
                }
            },
            //获取活动详情
            async getActivityDetail() {
                let { data } = await getActivitySwap({id: this.formData.id});
                if(data) {
                    this.activitySwap = {
                        keyId: data.id,
                        swapGoodsList: data.swapSkuInfos || [],
                        multipleChoiceFlag: !!data.multipleChoiceFlag,
                        usageState: !!data.usageState,
                        operateType: 2,
                        swapBeginTime:data.swapBeginTime,
                        swapEndTime:data.swapEndTime
                    };
                    this.goodsList = [{
                        id: data.goodsId,
                        goodsName: data.goodsName,
                        thumbImgUrl: data.thumbImgUrl,
                        salePrice: data.salePrice,
                        growRate: data.growRate,
                    }];
                    if (data.swapBeginTime) {
                        this.isShowTime = true;
                        this.showTime = [data.swapBeginTime,data.swapEndTime]
                    }
                }
            },
            //关闭
            cancelFunc(flag) {
                this.$emit('closeLayer', flag);
                this.clearValidate();
            },
            //清除表单验证
            clearValidate() {
                this.$refs["goodsCreateFrom"].clearValidate();
            },
            //选择商品
            selectGoods(typeName) {
                this.selectGoodsTitle = typeName === 'goods' ? '选择主商品' : '添加换购商品';
                this.selectGoodsData = {
                    type: typeName
                };
                this.selectGoodsFlag = true;
            },
            //确认选择
            getSelectList(data) {
                if(this.selectGoodsData.type === 'goods') {
                    this.goodsList.push(data);
                } else {
                    this.activitySwap.swapGoodsList = this.activitySwap.swapGoodsList.concat(data || []);
                }
                this.selectGoodsFlag = false;
            },
            //删除选择主商品
            deleteGoods({row, $index}) {
                this.goodsList.splice($index, 1);
            },
            //清楚选中内容
            clearDataFunc() {
                this.selectGoodsData = null;
            },
            //调整数值
            editSpecNum({row, $index}, name) {
                this.showChangeTitle = name === 'swapRate' ? '调整限购数量' : '调整排序';
                this.showChangeData = {
                    index: $index,
                    type: name,
                    value: row[name],
                    max: name === 'swapRate' ? row.swapStock : 10000,
                };
                this.showChangeNumFlag = true;
            },
            checkChangeNum() {
                let { index, type, value } = this.showChangeData;
                let item = {...this.activitySwap.swapGoodsList[index]};
                item[type] = value;
                this.activitySwap.swapGoodsList.splice(index, 1, item);
                if(type !== 'swapRate') {
                    this.activitySwap.swapGoodsList.sort(function(a,b){
                        return a.orderWeight - b.orderWeight
                    })
                }
                this.showChangeNumFlag = false;
            },
            //删除选中规格
            deleteSpec({$index}) {
                this.activitySwap.swapGoodsList.splice($index, 1);
            },
            //确认提交
            submitData() {
                let _this = this;
                this.$refs.goodsCreateFrom.validate(async (valid) => {
                    if (valid) {
                        let params = {
                            keyId: this.activitySwap.keyId || this.goodsList[0].id,
                            swapGoodsList: this.activitySwap.swapGoodsList,
                            multipleChoiceFlag: this.activitySwap.multipleChoiceFlag ? 1 : 0,
                            usageState: this.activitySwap.usageState ? 1 : 0,
                            operateType: this.activitySwap.operateType,
                        };
                        console.log(_this.isShowTime);
                        if (_this.isShowTime) { 
                            if (!_this.showTime.length) {
                                this.$message({type: 'error', message: '请选择活动时间！'});
                                return;
                            }else{
                                params.swapBeginTime = _this.showTime[0];
                                params.swapEndTime = _this.showTime[1];
                            }
                        }
                        let { success, alertMsg } = await editActivitySwap(params);
                        if(success) {
                            this.$message({type: 'success', message: '提交成功！'});
                            this.$emit('closeLayer', true);
                        } else {
                            this.$message({type: 'error', message: alertMsg || '未知错误！'});
                        }
                    }
                });
            },
        }
    }
</script>